import { Box, Button, Grid, InputLabel, FormControl, MenuItem, Select } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react"
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const NutrientCalculator = () => {
    const [data, setLatest] = useState({})
    const [zone, setZone] = useState("Western Himalayas (J & K, H.P.)")

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get/soil/nutrient/calculator`);
            if (response.data.status == 'success') {
                console.log(response.data);
                
                setLatest(response.data);
    
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }, []);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get/zone/soil/nutrient/calculator?zone=${zone}`);
            if (response.data.status == 'success') {
                console.log(response.data);
                setLatest(response.data);
    
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }, []);


      const columns = [
        {
          field: "id",
          headerName: "ID",
          width: 90,
          headerAlign: "center",
          align: "center",
          renderHeader: () => <strong style={{ color: "#334474" }}>{"SL. No"}</strong>,
          filterable: false,
          renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
          field: "humidity",
          headerName: "Humidity",
          width: 200,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "#334474" }}>{"Humidity"}</strong>
          ),
        },
        {
          field: "ph",
          headerName: "ph",
          width: 80,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "#334474" }}>{"pH"}</strong>
          ),
        },
        {
          field: "potassium",
          headerName: "Potassium",
          width: 220,
          headerAlign: "center",
          align: "center",
          renderHeader: () => <strong style={{ color: "#334474" }}>{"Potassium"}</strong>,
        },
        {
          field: "temperature",
          headerName: "temperature",
          width: 220,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "#334474" }}>{"Temperature"}</strong>
          ),
        },
        {
          field: "timestamp",
          headerName: "timestamp",
          width: 220,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "#57926e" }}>{"Timestamp"}</strong>
          ),
        },
        {
          field: "conductivity",
          headerName: "conductivity",
          width: 220,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "#57926e" }}>{"Conductivity"}</strong>
          ),
        },
        {
          field: "nitrogen",
          headerName: "nitrogen",
          width: 220,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "#57926e" }}>{"Nitrogen"}</strong>
          ),
        },
        {
          field: "phosphorus",
          headerName: "phosphorus",
          width: 220,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "#57926e" }}>{"Phosphorus"}</strong>
          ),
        },
      ];

      const islatestDataReady = data && data.datass && Array.isArray(data.datass);
       
       const isagroclimaticzonesReady = data && data.agroclimatic_zones && Array.isArray(data.agroclimatic_zones);
       

    return (
        <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>
                 <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Nutrient Calculator" className="text-sm-left" />
      </Row>
      {
        islatestDataReady ? <Row noGutters className="page-header py-2">
            
                  <DataGrid
                    getRowId={(data)=> data.ph+data.timestamp}
                    rows={data.datass}
                    columns={columns}
                    density="compact"
                    rowCount={100}
                    paginationMode="server"
                    components={{ Toolbar: GridToolbar }}
                  />
        </Row> : <Row noGutters className="page-header py-2">loading latestData...</Row>
      }

            <Row noGutters className="page-header py-2">
            {
                    isagroclimaticzonesReady && 
            <Box sx={{ maxWidth: 650 }}>
                <FormControl
              margin="normal"
              variant="outlined"
              fullWidth={true}
              sx={{ gap: 6 }}
            >

              <InputLabel id="asset-select-label" >Agroclimatic Zone</InputLabel>

              <Select
                labelId="asset-select-label"
                id="AgroclimaticZone"
                value={zone}
                onChange={(e) => setZone(e.target.value)}
              >
           {     data.agroclimatic_zones.map((zone,index)=> 
                      <MenuItem index={index} value={zone} name={zone}>{zone}</MenuItem>
                    
                    )}
              </Select>

              
            </FormControl>
            </Box>
        }

    </Row>


   </Container>
    )
}

export default NutrientCalculator