import axios from "axios";
import React, { useEffect, useState } from "react"
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Box, Chip, Grid, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/base";
import { Container, Row } from "shards-react";
import Plot from "react-plotly.js";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";


const weatherPrediction = () => {

    const [division, setDivision] = useState([])
    const [handleselecteddivision, setHandleSelectedDivision] = React.useState({});
    const [selectedDivisions, setSelectedDivision] = useState(["Andaman & Nicobar Islands"]);
    const [handlefutureprediction, sethandleFuturePrediction] = useState({});
    const [futureDate, setFutureDate] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/weather/prediction`
            );
            if (response.data.status == "success") {
                setDivision(response.data);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedDivisions.length > 0) {
            const fetchData = async () => {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/get/weather/predicted`, {
                    "subdivisions": selectedDivisions
                }
                );
                if (response.data.status == "success") {
                    setHandleSelectedDivision(response.data);
                    console.log(response.data);
                }
            };

            fetchData();
        }

    }, [selectedDivisions])

    useEffect(() => {
        if (selectedDivisions.length > 0) {
            const fetchData = async () => {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/get/weather/predicted/future`, {
                    "subdivisions": selectedDivisions,
                    "future_date": futureDate
                }
                );
                if (response.data.status == "success") {
                    sethandleFuturePrediction(response.data);
                }
            };

            fetchData();
        }

    }, [futureDate])

    const handledivisionChanges = (e, newLocation) => {
        setSelectedDivision(newLocation);
        setFutureDate("")
    };

    const columns = [

        {
          field: "ds",
          headerName: "Timestamp",
          width: 150,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "rgb(28, 70, 146)" }}>{"Timestamp"}</strong>
          ),
        },
        {
          field: "yhat",
          headerName: "Rainfall",
          width: 200,
          headerAlign: "center",
          align: "center",
          renderHeader: () => (
            <strong style={{ color: "rgb(28, 70, 146)" }}>{"Rainfall"}</strong>
          ),
        },
        

      ];

      const handleDateChange = (e) => {
        const value = e.target.value;
        setFutureDate(value);
      };

    return (
        <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>
            <Row noGutters className="page-header py-4">

                <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={division && division.subdivisions ? division.subdivisions : []}
                    getOptionLabel={(option) => option}
                    value={selectedDivisions}
                    onChange={(e, newVal) => handledivisionChanges(e, newVal)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="All Division"
                            placeholder="Division"
                        />
                    )}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#50C1E9",
                            },
                            "&:hover fieldset": {
                                borderColor: "#50C1E9",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#50C1E9",
                            },
                            width: "350px",
                        },
                    }}

                />
            </Row>

            {
    handleselecteddivision ? Object.keys(handleselecteddivision['actual-ds'] || {}).map((item, index) => {
        const actualDs = handleselecteddivision['actual-ds'][index];
        const actualY = handleselecteddivision['actual-y'][index];
        const forecastDs = handleselecteddivision['forecast-ds'][index];
        const forecastYhat = handleselecteddivision['forecast-yhat'][index];
        const subdivision = handleselecteddivision['subdivision'][index];
        const rsquare = handleselecteddivision['rsquare'][index];

        // Check if all required properties are defined
        if (actualDs && actualY && forecastDs && forecastYhat && subdivision && rsquare) {
            return (
                <>
                <Row key={index} noGutters className="py-4">
                    <Plot
                        data={[
                            {
                                x: Object.values(actualDs),
                                y: Object.values(actualY),
                                mode: 'lines',
                                name: 'Actual',
                            },
                            {
                                x: Object.values(forecastDs),
                                y: Object.values(forecastYhat),
                                mode: 'lines',
                                name: 'Forecast',
                            },
                        ]}
                        layout={{
                            title: `Actual vs Forecasted Rainfall for ${subdivision}`,
                            xaxis: {
                                title: 'Date',
                            },
                            yaxis: {
                                title: 'Rainfall (mm)',
                            },
                            template: 'plotly_white',
                        }}
                    />
                </Row>
                <Row noGutters className="py-2">
                R-squared Score: {rsquare}
                </Row>
                </>
            );
        } else {
            // Handle the case where any required property is missing
            return (
                <Row key={index}>
                    <div>Error: Missing data for index {index}</div>
                </Row>
            );
        }
    }) : ''
}

{
    handleselecteddivision ? Object.keys(handleselecteddivision['forecast'] || {}).map((item, index) => {
        const forecast = handleselecteddivision['forecast'][index];
        const subdivision = handleselecteddivision['subdivision'][index];
          
        if (forecast) {
            return (
                <>
                <Row noGutters className="py-1">Forecasted Rainfall for {subdivision} </Row>
                <Row key={index} noGutters className="py-4"  style={{ height: 500, width: 800 }}>
                 <DataGrid
                 getRowId={(row)=> row.yhat}
                rows={forecast}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 30]}
                density="compact"
                components={{ Toolbar: GridToolbar }}
                pagination
            />
                </Row>
                </>
            );
        } else {
            // Handle the case where any required property is missing
            return (
                <Row key={index}>
                    <div>Error: Missing data for index {index}</div>
                </Row>
            );
        }
    }) : ''
}
   
            <Row noGutters>Select a future date for forecasting:</Row>
            <Row noGutters className="py-4">
            <TextField
                  label="Start Time"
                  type="date"
                //   value={}
                onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontWeight: 700,
                      color: "#005778",
                      fontSize: 16,
                    },
                  }}
                  InputProps={{
                    style: {
                      fontSize: 15,
                      color: "rgba( 0, 0, 0, 70% )",
                      height: 55,
                    },
                  }}
                  style={{ marginRight: 30 }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#50C1E9",
                      },
                      "&:hover fieldset": {
                        borderColor: "#50C1E9",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#50C1E9",
                      },
                    },
                  }}
                />
            </Row>

            {
    handlefutureprediction ? Object.keys(handlefutureprediction['future_forecast-ds'] || {}).map((item, index) => {
        const forecastDs = handlefutureprediction['future_forecast-ds'][index];
        const forecastYhat = handlefutureprediction['future_forecast-yhat'][index];
        const subdivision = handleselecteddivision['subdivision'][index];
        if (forecastDs && forecastYhat) {
            return (
                <Row key={index} noGutters className="py-4">
                    <Plot
                        data={ [
                            {
                              x: Object.values(forecastDs),
                              y: Object.values(forecastYhat),
                              type: 'scatter',
                            //   mode: 'lines',
                              name: 'Forecast',
                            },
                          ]}
                        layout={{
                            title: `Forecasted Rainfall from ${futureDate.toString().split('T')[0]} for ${subdivision} `,
                            xaxis: {
                              title: 'Date',
                            },
                            yaxis: {
                              title: 'Rainfall (mm)',
                            },
                            template: 'plotly_white',
                          }}
                    />
                </Row>
            );
        } else {
            // Handle the case where any required property is missing
            return (
                <Row key={index}>
                    <div>Error: Missing data for index {index}</div>
                </Row>
            );
        }
    }) : ''
}

        </Container>
    )
}

export default weatherPrediction